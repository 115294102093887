import { render, staticRenderFns } from "./Voucher.vue?vue&type=template&id=56bbf904&scoped=true&"
import script from "./Voucher.vue?vue&type=script&lang=js&"
export * from "./Voucher.vue?vue&type=script&lang=js&"
import style0 from "./Voucher.vue?vue&type=style&index=0&id=56bbf904&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56bbf904",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56bbf904')) {
      api.createRecord('56bbf904', component.options)
    } else {
      api.reload('56bbf904', component.options)
    }
    module.hot.accept("./Voucher.vue?vue&type=template&id=56bbf904&scoped=true&", function () {
      api.rerender('56bbf904', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8QYU0IPcKN6zM/JS/comp/Voucher.vue"
export default component.exports